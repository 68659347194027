<template>
  <div class="main-page-content">
    <el-button @click="handleClick()"
               key="artist_divide_item"
               type="danger"
               style="margin: 6px"
               v-if="userPermissions.indexOf('rebate_import') !== -1">
      撤回
    </el-button>
    <br>
    <br>
    <div class="default-table">
      <el-table :data="dataList" ref="multipleTable" row-key="id"
             border>
        <el-table-column
            type="selection"
            width="40">
        </el-table-column>
        <el-table-column align="center" label="序号" type="index" width="60"></el-table-column>
        <el-table-column align="center" label="月份"
                         show-overflow-tooltip prop="month"></el-table-column>
        <el-table-column align="center" label="红人昵称" min-width="150" prop="nickname"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="right" label="金额" prop="rebate" show-overflow-tooltip
                         :formatter="formatNumber" ></el-table-column>
      </el-table>
    </div>
    <el-row justify="right" type="flex">
      <el-col :span="24">
        <Pagination
            :limit.sync="pagingData.page_size"
            :page.sync="pagingData.current_page"
            :total="pagingData.total"
            @pagination="initList"/>
      </el-col>
    </el-row>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'oaTable',
  data() {
    return {
      loadingStatus: false,
      // 表格列表数据
      dataList: [],
      // 分页信息
      pagingData: {
        page_size: 15,
        current_page: 1,
        total: 0
      },
      options: { status: {}, source_types: {} },
      orderSort: { id: 'desc' }//默认排序规则
    }
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  methods: {
    //批量样式居中
    headClass() {
      return 'text-align:center'
    },
    async initList() {
      this.loadingStatus = true
      // let pagingInfo = this.$refs['apeTable'].getPagingInfo(type)
      let searchCondition = {}
      Object.assign(searchCondition, {
        page_size: this.pagingData.page_size || 10,
        current_page: this.pagingData.current_page || 1
      }, { import_id: this.$route.params.id })
      let { list, page_info } = await this.$api.rebateGetKolList(searchCondition)
      this.dataList = list
      this.pagingData = page_info
      this.loadingStatus = false
    }
    ,
    formatNumber(row, col) {
      return this.$utils.numberFormat(row[col.property], 2, '.', ',', 'round')
    },
    async handleClick() {
      // /saveOaManage
      this.$confirm('是否确定撤回该条导入', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let data = {
          import_id: this.$route.params.id
        }
        let id = await this.$api.rebateDelImport(data)
        if (id) {
          this.$notify.success('成功')
          setTimeout(() => {
            window.close()
          }, 800)

        } else this.$notify.error('操作失败')
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    }
  },
  mounted() {
    this.initList()
  }
}
</script>

<style scoped>

</style>
